@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base{
  button{
    @apply border py-2 px-3 rounded-full
  }
}
